<div class="bg-white max-w-5xl mx-auto p-2 md:p-10">
  <div class="mb-5">
    <div class="text-lg">Cập nhật thông tin</div>
    <span v-if="is_invite != 'false'" class="text-xs text-gray-600">
      Vui lòng cập nhật mật khẩu cho lần đầu đăng nhập
    </span>
  </div>
  <Form
    as="el-form"
    :validation-schema="updateProfile"
    ref="reset"
    @submit="confirmUpdataProfile"
    label-position="left"
  >
    <el-form-item class="w-full" label="Email" label-width="155px">
      <el-input label="Email" v-model="userInfo.emailQuanLyTaiKhoan" disabled />
    </el-form-item>
    <el-form-item class="w-full" label="Vai trò" label-width="155px">
      <el-input label="Vai trò" v-model="userInfo.userType" disabled />
    </el-form-item>
    <el-form-item class="w-full" label="Ảnh đại diện" label-width="155px">
      <small v-if="!this.userInfo.avatar_url" class="text-gray-500">*Kẻo thả ảnh tải lên <i>.Tệp .jpeg, .jpg, .png, nhỏ hơn 2MB </i></small>
      <el-upload
        class="avatar-uploader"
        drag
        action=""
        :show-file-list="false"
        :on-change="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <div class="p-2 h-full" v-if="this.userInfo.avatar_url">
          <img :src="userInfo.avatar_url.replace('200x200/', '')" class="avatar m-auto object-cover rounded-lg h-40 w-40">
        </div>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <el-button type="danger" size="mini" icon="el-icon-delete" @click="userInfo.avatar_url = null">xóa ảnh đại diện</el-button>
    </el-form-item>
    <el-form-item class="w-full" label="Họ tên" label-width="155px">
      <InputWithValidation
        label="Họ tên"
        v-model="userInfo.hoTen"
        name="hoTen"
      />
    </el-form-item>
    <el-form-item
      class="w-full input-none"
      label="is_invite"
      label-width="155px"
    >
      <InputWithValidation
        label="is_invite"
        v-model="is_invite"
        name="is_invite"
      />
    </el-form-item>
    <el-form-item class="w-full" label="Số điện thoại" label-width="155px">
      <InputWithValidation
        type="tel"
        label="Số điện thoại"
        v-model="userInfo.soDienThoai"
        name="soDienThoai"
      />
    </el-form-item>
    <el-form-item class="w-full" label="Mật khẩu mới" label-width="155px">
      <InputWithValidation
        type="password"
        v-model="password.newPassword"
        label="Mật khẩu mới"
        name="matKhauMoi"
      />
    </el-form-item>
    <el-form-item class="w-full" label="Xác nhận mật khẩu" label-width="155px">
      <InputWithValidation
        type="password"
        label="Xác nhận mật khẩu"
        v-model="password.confirmPassword"
        name="xacNhanMatKhau"
      />
    </el-form-item>
    <el-form-item class="w-full" label="Ngày hết hạn" label-width="155px">
      <el-input label="Ngày hết hạn" v-model="userInfo.expired_at" disabled />
    </el-form-item>
    <div class="dialog-footer text-right itax-button">
      <el-button size="mini" type="primary" native-type="submit">
        Xác nhận
      </el-button>
    </div>
  </Form>
</div>
