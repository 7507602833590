import { Form } from "vee-validate";
import * as yup from "yup";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import { URL as USER_URL } from "@/api/users";
import { mapActions } from "vuex";
import formatDate from "@/plugins/formatDate.js";
import { ref } from "vue";
import _ from "lodash";
import auth from "@/api/auth";

export default {
  components: {
    Form,
    InputWithValidation,
  },
  data() {
    return {
      date: null,
      userInfo: {},
      password: {},
      activeTabs: "profile",
      is_invite: "true",
      upload_image_path: ""
    };
  },
  setup() {
    const updateProfile = yup.object({
      hoTen: yup.string().nullable(),
      soDienThoai: yup
        .string()
        .matches(/^\d*$/, {
          message: "Số điện thoại không đúng định dạng",
        })
        .max(11, "Số điện thoại tối đa 11 ký tự")
        .nullable(),
      is_invite: yup.boolean(),
      matKhauMoi: yup
        .string()
        .nullable()
        .oneOf([yup.ref("matKhauMoi")], "Mật khẩu không khớp")
        .transform((v) => (v === "" ? null : v))
        .when("is_invite", {
          is: true,
          then: yup
            .string()
            .min(8, "Mật khẩu ít nhất 8 ký tự")
            .required("Mật khẩu là một trường bắt buộc")
            .typeError("Mật khẩu là một trường bắt buộc"),
        }),
      xacNhanMatKhau: yup
        .string()
        .nullable()
        .oneOf([yup.ref("matKhauMoi")], "Mật khẩu không khớp")
        .transform((v) => (v === "" ? null : v)),
    });

    const reset = ref(null);

    return {
      reset,
      updateProfile,
    };
  },
  async created() {
    this.is_invite = JSON.parse(localStorage.getItem("is_invite")).toString();
    await this.getUserInfo();
    this.upload_image_path = USER_URL.UPDATE_USER.replace(":id", this.userInfo.id);
  },
  methods: {
    ...mapActions(["loading"]),

    changeTabs() {
      this.password = {};
    },

    async confirmUpdataProfile() {
      this.loading(true);

      const data = {};
      data.emailQuanLyTaiKhoan = this.userInfo.emailQuanLyTaiKhoan;
      data.hoTen = this.userInfo.hoTen;
      data.soDienThoai = this.userInfo.soDienThoai;
      data.user_name = this.userInfo.user_name;
      data.avatar_url = this.userInfo.avatar_url;
      if (this.password.newPassword) {
        data.password = this.password.newPassword;
        data.password_confirm = this.password.confirmPassword;
      }

      var d = new Date(this.date);
      var datestring = `${d.getFullYear()}-
      ${("0" + (d.getMonth() + 1)).slice(-2)}-
      ${("0" + d.getDate()).slice(-2)}`;
      var date = datestring.replace(/\s+/g, "");
      data.expired_at = `${date} 23:59:59`;

      const result = await this.$request({
        url: USER_URL.UPDATE_USER.replace(":id", this.userInfo.id),
        method: "PUT",
        data,
      });
      const res = result.data;

      if (res.code == 200) {
        this.userInfo = res.data;
        this.userInfo.expired_at = formatDate(this.userInfo.expired_at);
        this.$store.dispatch("setAuthUser", res.data);
        this.$swal({
          text: "Cập nhật thành công",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        }).then(() => {
          window.location.reload()
        });
      }
      if (!res.success) {
        this.$swal({
          text: res.messages,
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }

      this.loading(false);
    },
    async handleAvatarSuccess(file) {
      if (file.status == 'ready') {
        const file_raw = file.raw
        const isValidExtention = ['image/jpeg', 'image/png'].indexOf(file_raw.type) != -1;
        const isLt2M = file_raw.size / 1024 / 1024 < 2;
        if (isValidExtention && isLt2M) {
          // this.userInfo.avatar = URL.createObjectURL(file_raw);
          let data = new FormData();
          data.append("file", file_raw);

          let res = await this.$request({
            url: USER_URL.UPDATE_USER.replace(":id", this.userInfo.id),
            method: "POST",
            data,
          });

          res = res.data
          if (res.code == 200) {
            this.userInfo.avatar_url = res.data
          }
        }
      }
    },
    beforeAvatarUpload(file) {
      const isValidExtention = ['image/jpeg', 'image/png'].indexOf(file.type) != -1;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isValidExtention) {
        this.$message.error('Ảnh phải có định dạng .png, .jpeg, .jpg');
      }
      if (!isLt2M) {
        this.$message.error('Ảnh phải có dung lượng <= 2MB');
      }
      return isValidExtention && isLt2M;
    },
    async getUserInfo() {
      const res = await this.$request({
        url: auth.GET_USER_INFO,
      });
      if (res.status == 200) {
        var userJson = JSON.stringify(res.data.data);
        localStorage.setItem("user", userJson);
        this.userInfo = res.data.data;
        this.date = _.cloneDeep(this.userInfo.expired_at);
        this.userInfo.expired_at = formatDate(this.userInfo.expired_at);
        let status = "false";
        if (res.data.data.is_invited) {
          status = "true";
          this.$router.push({ name: "profile" });
        }
        localStorage.setItem("is_invite", status);
      }
    },

    formatDate,
  },
};
